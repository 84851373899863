import React from 'react'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import ModalManager from '../../modals/ModalManager'
import VegaLink from '../VegaLink'
//import NavList from './NavList'

import vegaIcon from '../../images/vega_combined.jpg'

function HeaderAnon({location, history}) {
  function onSearchTextChange(e) {
    //const searchText = e.target.value
    //TODO: autocomplete
  }

  function searchKeyPressed(e){
    if(e.charCode === 13){ //ENTER
      const searchText = e.target.value
      const to = `/search?searchText=${searchText}`
      history.push(to)
    }
  }

  const defaultSearchText = queryString.parse(location.search).searchText || ""

  return <div className="fixed-top">
    <nav className="Header navbar navbar-inverse">
      <div className="container">
        <span>
          <VegaLink to="/events">
            <img src={vegaIcon} className="header-pic ml-lg-2 mx-3" alt=""/>
          </VegaLink>
        </span>
        <span className="anon-search">
          <div id="searchbar" className="form-group has-search">
            <span className="fa fa-search form-control-feedback"></span>
            <input type="text" defaultValue={defaultSearchText} className="form-control" placeholder="Search Vega" onKeyPress={searchKeyPressed} onChange={onSearchTextChange}/>
          </div>
        </span>
        <span className="navbar-text">
          <span onClick={()=>ModalManager.current.show("LogIn")} className="VegaLink">Login</span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span onClick={()=>ModalManager.current.show("SignUp")} className="VegaLink">Sign Up</span>
        </span>
      </div>
    </nav>
  </div>
}

export default withRouter(HeaderAnon)
