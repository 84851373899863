import React, { Component } from 'react'

const PROMISE_CANCELLED = "cancelled"

export default class VegaTextBox extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      validating: false,
      validated: false,
      error: null,
      type: "text"
    }
    props.form.handleValidationChange(props.id,this.state.validated)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({type: nextProps.type || "text"})
  }

  handleChange(e) {
    this.props.form.onTextChange(e)
    const value = e.target.value
    if(this.props.validate) {
      if(this.state.validating) {
        console.log(this.validationProm)
        console.log(this.validationProm.reject)
        if(this.validationProm.reject!==undefined) { //if defined
          this.validationProm.reject(PROMISE_CANCELLED)
        }
      }
      if(value==="") {
        this.props.form.handleValidationChange(this.props.id,false)
        this.setState({validating:false, validated:false, error:null})
        return;
      }
      this.setState({validating:true})
      let prom
      try {
        prom = this.props.validate(value)
      } catch(error) {
        console.log(error)
        this.props.form.handleValidationChange(this.props.id,false)
        this.setState({validating:false, error:error})
        return
      }
      this.validationProm = Promise.resolve(prom)
        .then((validated)=>{
          this.props.form.handleValidationChange(this.props.id,true)
          this.setState({validating:false, validated:true, error:null})
        }, (error)=>{
          console.error(error)
          if(error!==PROMISE_CANCELLED) {
            this.props.form.handleValidationChange(this.props.id,false)
            this.setState({validating:false, error:error})
          }
        })
    } else {
      const valid = (value!=="")
      this.setState({error:valid?null:"This cannot be empty"})
      this.props.form.handleValidationChange(this.props.id,valid)
    }
  }

  static getErrorText(error) {
    return error
  }

  render() {
    return <div className="vega-form-group">
      <input
        id={this.props.id}
        value={this.props.form.state[this.props.id]}
        onChange={this.handleChange}
        placeholder={this.props.placeholder}
        className={`form-control vega-form-control
          ${this.state.validating ? "validating":""}
          ${this.state.validated ? "validated":""}
          ${this.state.error!==null ? "error":""}`}
        type={this.state.type}/>
      <span className="VegaTextBox-error">{this.state.error!==null ? VegaTextBox.getErrorText(this.state.error) : null}</span>
    </div>
  }
}
