import React, { Component } from 'react'

export default class ModalManager extends Component {
  static current

  constructor(props) {
    super(props)

    this.state = {
      child: null,
      props: {}
    }
    this.childModals={}

    this.componentWillReceiveProps(props)

    this.handleHide = this.handleHide.bind(this)

    ModalManager.current = this
  }

  componentWillReceiveProps(nextProps) {
    //console.log("componentWillReceiveProps")
    this.childModals = {}
    //console.log(nextProps)
    nextProps.children.forEach((child)=>{
      this.childModals[child.props.id] = child
    })
    //console.log(this.childModals)
  }

  show(id,props={}) {
    this.setState({child:this.childModals[id],props})
  }

  hide(id) {
    if(id==="all" || this.state.child.props.id===id) {
      this.setState({child:null})
    }
  }

  handleHide(e) {
    e.preventDefault()
    if(e.target===e.currentTarget){
      ModalManager.current.hide("all")
    }
  }

  render() {
    if(this.state.child===null) {return null}
    return <div id="ModalManager" onClick={this.handleHide} className="modal fade show" role="dialog">
      <div className="modal-dialog modal-dialog-centered">
        {React.cloneElement(this.state.child, this.state.props)}
      </div>
    </div>
  }
}
