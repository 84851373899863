import React from 'react'
import Parse from 'parse'
import Container from './Container'
import OrgInvite from '../components/OrgInvite'

const VegaOrganization = Parse.Object.extend("Organization")

export default function OrgInviteContainer({orgUsername}) {
  function fetchData() {
    return new Parse.Query(VegaOrganization)
      .equalTo("username", orgUsername)
      .first().then((org)=>{
        const coverPhoto = org.get("coverPhoto")
        return {
          orgUsername: org.get("username"),
          name: org.get("name"),
          imgSrc: coverPhoto ? coverPhoto.url() : undefined
        }
      })
  }

  return <Container fetchData={fetchData} component={OrgInvite}/>
}
