import React from 'react'
import VegaLink from './VegaLink'

import defaultImage from '../images/vega_icon.png'

export default function OrganizationCell({thumbnailSrc, name, subName, username}) {
  return (<VegaLink to={`/org/${username}`} noStyling>
    <div className="card OrganizationCell">
      <div className="card-body">
        <div className="left-thumbnail">
          <img className="organization-cell-thumbnail" src={thumbnailSrc ? thumbnailSrc : defaultImage} alt=""/>
        </div>
        <div>
          <h3>{name}</h3>
          <p>@{username}</p>
        </div>
      </div>
    </div>
  </VegaLink>)
}
