import React, { Component } from 'react'
import vega_logo from '../../images/vega_logo.png'

export default class Mobile extends Component {
  constructor(props) {
    require('../../css/mobile.css')

    super(props)
  }
  render() {
    return <div className="Mobile text-center">
      <meta name="apple-itunes-app" content="app-id=1080421391"/>
      <img className="mobile-vega-logo" src={vega_logo} alt="vega logo"/><br/><br/>
      <h3>The mobile webapp<br/>is coming!</h3>
      <p>Vega already has great native<br/>apps for both major platforms.</p>
      <p><a href="http://onelink.to/63tdnt">Download Now!</a></p>
    </div>
  }
}
