import React from 'react'
import Parse from 'parse'
import FormComponent from './FormComponent'

export default class CreateOrganization extends FormComponent {
  static fields = {
    coverPhoto: "IMAGE",
    name: "TEXT",
    subName: "TEXT",
    username: "TEXT",
    about: "TEXT"
  }

  constructor(props){
    super(props, CreateOrganization)
  }

  async onSave() {
    Parse.Cloud.run("createOrganization", await this.getFieldValues()).then((org)=>{
      let username = org.get("username")
      this.props.history.push(`/org/${username}`)
    })
  }

  render() {
    return (
      <div className="EditEventInfo">
        <div>
          {this.state.coverPhoto_src ? (<div><img className="event-info-img" src={this.state.coverPhoto_src} alt={this.state.title + " image"}/><br/></div>) : ""}
          <br/>
          <div className="input-group">
            <input id="name" onChange={this.onTextChange} value={this.state.name} placeholder="Name" type="text" className="form-control form-control-lg"/>
            <div className="input-group-append">
              <label htmlFor="coverPhoto" className="input-group-text edit-profile-pic-label btn">
                <i className="fas fa-camera"/>
              </label>
            </div>
          </div>
          <input id="coverPhoto" onChange={this.onImageChange} name="coverPhoto" type="file"/>
          <br/>
          <div className="input-group">
            <span className="input-group-prepend"><span className="input-group-text">Subname</span></span>
            <input id="subName" onChange={this.onTextChange} value={this.state.subName} placeholder="(i.e., The official account of ...)" type="text" className="form-control"/>
          </div><br/>
          <div className="input-group">
            <span className="input-group-prepend"><span className="input-group-text">@</span></span>
            <input id="username" onChange={this.onTextChange} value={this.state.username} placeholder="username" type="text" className="form-control"/>
          </div><br/>
          <div className="form-group">
            <label htmlFor="about" className="control-label">ABOUT</label>
            <textarea id="about" onChange={this.onTextChange} name="about" value={this.state.about} className="form-control about-edit-text" />
          </div><br/>
          <div className="btn btn-vega-light" onClick={this.onSave}>Register</div><br/>
        </div>
      </div>
    );
  }
}
