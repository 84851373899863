import React from 'react';
import DateUtil from '../js/DateUtil';
//import EventInfoHeader from './EventInfoHeader';

export default function EventInfo({imgSrc,title,organizationName,startTime,endTime,locationName,about,ownEvent}) {
  return (
    <div className="EventInfo card">
      <img className="card-img-top event-info-pic" src={imgSrc} alt={title + " image"}/>
      <div className="card-body">
        <div className="event-info-data container">
          <h2 className="text-center event-info-title">{title}</h2>
          <p>{DateUtil.getDateRangeString(startTime,endTime)}</p>
          <p>{organizationName}</p>
          <p>{locationName}</p>
          <div>
            {about.split("\n").map((p,i)=><p key={i}>{p}</p>)}
          </div>
        </div>
        { ownEvent ? <div className="btn btn-vega-light" onClick={this.handleEdit}>Edit</div> : null}
      </div>
    </div>
  );
}
