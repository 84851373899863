import React from 'react'
import EventCell from './EventCell'
import OrganizationCell from './OrganizationCell'
import TagCell from './TagCell'

export default function Search({results,topResult,events,organizations,tags}) {
  //console.log("Search", results, topResult)
  if(topResult===null) {
    return <p>No results matched your search.</p>
  }
  return <div className="Search">
    <SearchHeader top>Top Result</SearchHeader>
    <SearchResultCard result={topResult}/>
    <SearchHeader describes={tags}>Tags</SearchHeader>
    {tags.map((org,i)=><SearchResultCard key={i} result={org}/>)}
    <SearchHeader describes={organizations}>Organizations</SearchHeader>
    {organizations.map((org,i)=><SearchResultCard key={i} result={org}/>)}
    <SearchHeader describes={events}>Events</SearchHeader>
    {events.map((event,i)=><SearchResultCard key={i} result={event}/>)}
  </div>
}

function SearchHeader({top,children,describes}) {
  return (top||describes.length!==0) ? <h2 className={`SearchHeader ${top ? "top" : ""}`}>{children}</h2> : null
}

function SearchResultCard({result}) {
  switch (result.className) {
    case "VegaEvent":
      return <EventCell {...result}/>
    case "Organization":
      return <OrganizationCell {...result}/>
    case "Tag":
      return <TagCell {...result}/>
    default:
      return null
  }
}
