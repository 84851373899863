import React from 'react';
import DateUtil from '../js/DateUtil'
import VegaLink from './VegaLink'

const MAX_ABOUT_LEN = 300;

export default function EventCell({id, title, startTime, locationName, about, thumbnailSrc}) {
  const trucAbout = about.length > MAX_ABOUT_LEN ? about.substring(0,MAX_ABOUT_LEN)+"..." : about
  return (<VegaLink to={`/event/${id}`} noStyling>
    <div className="card EventCell">
      <div className="card-body">
        <div className="row">
          <div className="col-9">
            <h4 className="event-cell-title">{title}</h4>
            <div><i className="far fa-clock"/><span className="time"> {DateUtil.getDateStringBrief(startTime)}</span></div>
            <div><i className="fas fa-map-marker-alt"/> {locationName}</div>
            <br/>
            <div className="event-cell-about">{trucAbout}</div>
          </div>
          <div className="col-3">
            <img className="event-cell-thumbnail" src={thumbnailSrc} alt={title + " image"}/>
          </div>
        </div>
      </div>
    </div>
  </VegaLink>);
}
