import React from 'react'
import Parse from 'parse'
import Container from './Container'
import Search from '../components/Search'

export default function SearchContainer({searchText}) {
  function fetchData() {
    return Parse.Cloud.run("searchAll",{searchText}).then((results)=>({
      results: results,
      topResult: results.topResult ? getResult(results.topResult) : null,
      events: results.events.map(getResult),
      organizations: results.organizations.map(getResult),
      tags: results.tags.map(getResult)
    }))
  }

  return <Container fetchData={fetchData} component={Search}/>
}

function getResult(parseObj) {
  let coverPhoto;
  switch (parseObj.className) {
    case "VegaEvent":
      coverPhoto = parseObj.get("coverPhoto")
      return {
        className: "VegaEvent",
        id: parseObj.id,
        title: parseObj.get("title"),
        thumbnailSrc: coverPhoto ? coverPhoto.url() : undefined,
        about: parseObj.get("about"),
        locationName: parseObj.get("location") ? parseObj.get("location").get("name") : null
      }
    case "Organization":
      coverPhoto = parseObj.get("coverPhoto")
      return {
        className: "Organization",
        id: parseObj.id,
        name: parseObj.get("name"),
        username: parseObj.get("username"),
        thumbnailSrc: coverPhoto ? coverPhoto.url() : undefined,
      }
    case "Tag":
      return {
        className: "Tag",
        id: parseObj.id,
        text: parseObj.get("text")
      }
    default:
      return {}
  }
}
