import React from 'react'
import Parse from 'parse'
import { withRouter } from 'react-router-dom'

function OrgInvite({orgUsername,name,imgSrc,history}) {
  return <div className="card">
    <div className="card-body">
      <img className="card-img-top" src={imgSrc} alt={name + " image"}/>
      <h5 className="card-text text-center">{name} invited you to their organization!</h5>
      <br/>
      <div className="row">
        <div className="col-6">
          <div onClick={()=>decline(orgUsername,history)} className="w-100 btn btn-danger">Decline</div>
        </div>
        <div className="col-6">
          <div onClick={()=>accept(orgUsername,history)} className="w-100 btn btn-success">Accept</div>
        </div>
      </div>
    </div>
  </div>
}

function accept(orgUsername,history) {
  Parse.Cloud.run("acceptInvite", {orgUsername}).then(()=>{
    history.push(`/org/${orgUsername}`)
  })
}

function decline(orgUsername,history) {
  Parse.Cloud.run("declineInvite", {orgUsername}).then(()=>{
    history.goBack()
  })
}

export default withRouter(OrgInvite)
