import React from 'react'
import Parse from 'parse'
import { withRouter } from 'react-router-dom'
import FormComponent from './FormComponent'

class InviteUser extends FormComponent {
  static fields = {
    username: "TEXT",
  }

  constructor(props){
    super(props, InviteUser)
  }

  async onSave() {
    await this.inviteUser()
  }

  inviteUser(){
    return Parse.Cloud.run("inviteUserToOrgByUsername",{
      orgUsername: this.props.orgUsername,
      username: this.state.username
    }).then((response)=>{
      this.props.refreshMembers()
    })
  }

  render() {
    return (<div className=" InviteUser row">
      <div className="col-8">
        <div className="input-group">
          <span className="input-group-prepend"><span className="input-group-text">@</span></span>
          <input id="username" onChange={this.onTextChange} value={this.state.username} type="text" placeholder="username" className="form-control"/>
        </div>
      </div>
      <div className="col-4">
        <div onClick={this.onSave} className="btn btn-vega-light w-100">Invite User</div>
      </div>
    </div>)
  }
}

export default withRouter(InviteUser)
