import React from 'react'
import Parse from 'parse'
import EventInfo from '../components/EventInfo'
import Container from './Container'

const VegaEvent = Parse.Object.extend("VegaEvent")

export default function EventInfoContainer({eventId}) {
  function fetchData(){
    return new Parse.Query(VegaEvent)
      .get(eventId)
      .then((event)=>{
        const coverPhoto = event.get("coverPhoto")
        return {
          imgSrc: coverPhoto? coverPhoto.url() : null,
          title : event.get("title"),
          organizationName : event.get("organizationName"),
          startTime : event.get("startTime"),
          endTime : event.get("endTime"),
          locationName : event.get("locationName"),
          about : event.get("about"),
          ownEvent : false //TODO add in owned event
        }
      })
  }
  return <Container fetchData={fetchData} component={EventInfo}/>
}
