import React from 'react'
import Parse from 'parse'
import { withRouter } from 'react-router-dom'
import FormComponent from './FormComponent'

class InviteUser extends FormComponent {
  static fields = {
    name: "TEXT",
  }

  constructor(props){
    super(props, InviteUser)
  }

  onSave() {
    return Parse.Cloud.run("addRole",{
      orgUsername: this.props.orgUsername,
      name: this.state.name
    }).then((response)=>{
      this.props.refreshRoles()
    })
  }

  render() {
    return (<div className=" InviteUser row">
      <div className="col-8">
        <div className="input-group">
          <input id="name" onChange={this.onTextChange} value={this.state.name} type="text" placeholder="Role Name" className="form-control"/>
        </div>
      </div>
      <div className="col-4">
        <div onClick={this.onSave} className="btn btn-vega-light w-100">Add Role</div>
      </div>
    </div>)
  }
}

export default withRouter(InviteUser)
