import React from 'react'
import under_construction_img from '../images/construction.svg'

export default function UnderConstruction() {
  return <div className="UnderConstruction card">
    <div className="card-body">
      <div className="text-center">
        <img className="under_construction_img" src={under_construction_img} alt="under construction"/>
        <h3>Put on your hard hat!</h3>
      </div>
      <p>The Vega webapp is still under construction!  It does not yet have all of the features that it will.  Come back in a few days; chances are it will be done by then!</p>
    </div>
  </div>
}
