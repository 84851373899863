/**
 * Container
 *
 * Used in all other container objects.  Lookup React's container design pattern
 */

import React, { Component } from 'react'
import ParseUtil from '../js/ParseUtil'
import LoadingText from '../components/LoadingText'

const PROMISE_CANCELLED = "cancelled"

export default /*abstract*/ class Container extends Component {
  static defaultProps = {
    loadingJSX: <LoadingText/>
  }

  constructor(props) {
    super(props)

    if(props.component===undefined){
      console.error("Container: no innerJSX defined")
    }

    this.state = {
      loading: true
    }

    this.handleChange = this.handleChange.bind(this)
    this.loadData = this.loadData.bind(this)
    this.refresh = this.refresh.bind(this)
  }

  loadData() {
    this.setState({loading:true})
    this.promise = this.props.fetchData()
    this.promise.then((data)=>{
      this.setState({data:data,loading:false})
    },(error)=>{
      if(error!==PROMISE_CANCELLED){
        console.error("Container: Failed fetching data", error)
        if(error.code===209) { //Invalid session token
          ParseUtil.handleInvalidSessionToken()
        }
      }
    })
  }

  componentDidMount() {
    this.loadData()
  }

  componentWillReceiveProps() {
    this.loadData()
  }

  componentWillUnmount() {
    if(this.state.loading){
      if(this.promise.reject) {
        this.promise.reject(PROMISE_CANCELLED) // XXX: Improve promise cancellation
      }
      this.setState({loading: false})
    }
  }

  handleChange(changedData) {
    console.log(changedData)
    const newData = Object.assign(this.state.data, changedData)
    this.setState({data:newData})
  }

  refresh() {
    this.loadData()
  }

  render() {
    if(this.state.loading){
      //console.log("loading")
      return this.props.loadingJSX
    } else {
      const InnerTag = this.props.component
      return <InnerTag {...this.props.props} {...this.state.data} onChange={this.handleChange} refreshContainer={this.refresh}/> //This is two peices of unusual syntax together.  Lookup tag naming (capitolization) and the JS 'spread operator'
    }
  }
}
