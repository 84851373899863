import React from 'react'
import EventList from '../components/EventList'
import Container from './Container'

import Parse from 'parse'

export default function SavedEventsContainer() {
  return <Container fetchData={fetchData} component={EventList} props={{emptyMessage:"You have no saved events"}}/>
}

function fetchData() {
  return Parse.Cloud.run("getSavedVegaEvents", {}).then((events)=>({
    events: events.map((event)=>{
      const coverPhoto = event.get("coverPhoto")
      return {
        id: event.id,
        title: event.get("title"),
        thumbnailSrc: coverPhoto ? coverPhoto.url() : null,
        startTime: event.get("startTime"),
        locationName: event.get("location").get("name"),
        about: event.get("about")
      }
    })
  }))
}
