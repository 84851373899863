import randomstring from 'randomstring'

export default class SettingsUtil {
  static settings = {
    sortType: 0,
    location: {
      coordinate: {
        latitude: 0,//TODO: better initial guess
        longitude: 0
      }
    },
    radiusValue: 19,
    radius: 10000,
  }

  static subscriptions = []

  static setSettings(obj) {
    Object.assign(SettingsUtil.settings, obj)
    SettingsUtil.subscriptions.forEach((subscription)=>{
      subscription.callback(SettingsUtil.settings)
    })
  }

  static subscribeToSettingChange(callback) {
    const id = randomstring.generate()
    SettingsUtil.subscriptions.push({id,callback})
    //console.log("subscriptions",SettingsUtil.subscriptions)
    return new SettingsSubscription(id)
  }
}

class SettingsSubscription {
  constructor(id) {
    this.id = id
  }

  unsubscribe() {
    for(var i=0;i<SettingsUtil.subscriptions.length;i++) {
      const subscription = SettingsUtil.subscriptions[i]
      if(subscription.id===this.id) {
        SettingsUtil.subscriptions.splice(i,1)
        return;
      }
    }
  }
}
