import React, { Component } from 'react'
import SettingsUtil from '../js/SettingsUtil'
import EventList from './EventList'

export default class HomeFeed extends Component {
  componentWillMount() {
    this.subscription = SettingsUtil.subscribeToSettingChange(this.props.refreshContainer)
  }
  componentWillUnmount() {
    this.subscription.unsubscribe()
  }
  render() {
    return <div className="HomeFeed">
      <EventList events={this.props.events} emptyMessage="There are no events in your feed."/>
    </div>
  }
}
