import React from 'react'
import VegaLink from './VegaLink'

export default function TagCell({text}) {
  return <div className="card TagCell">
    <VegaLink to={`/tag/${text}`}>
      <div className="card-body">
        <p className="card-text">#{text}</p>
      </div>
    </VegaLink>
  </div>
}
