import React, { Component } from 'react'
import Parse from 'parse'
import ModalManager from '../modals/ModalManager'

export default class LocationPicker extends Component {
  constructor(props) {
    super(props)

    this.state = {
      picking:false,
      location:""
    }

    this.handleCommitLocation = this.handleCommitLocation.bind(this)
    this.pick = this.pick.bind(this)
  }

  handleCommitLocation(selectedLocation) {
    if(this.props.onChange) {
      this.props.onChange(selectedLocation)
    }

    if(this.props.form) {
      const fullName = selectedLocation.subtitle ? selectedLocation.title+", "+selectedLocation.subtitle : selectedLocation.title
      const parseLocation = {
        coordinate: new Parse.GeoPoint(selectedLocation.coordinate.latitude,selectedLocation.coordinate.longitude),
        fullAddress: fullName,
        name: selectedLocation.title,
        title: selectedLocation.fullName,
        countryCode: selectedLocation.countryCode,
        custom: selectedLocation.custom
      }
      this.props.form.onLocationChange(this.props.id, parseLocation)
    }

    this.setState({
      location:selectedLocation.title,
    })
    ModalManager.current.hide("LocationPicker")
  }

  pick() {
    ModalManager.current.show("LocationPicker",{commitLocation:this.handleCommitLocation})
  }

  render() {
    return (<div className="vega-form-group">
      <input id="location" onFocus={this.pick} value={this.state.location} placeholder="Select Location" type="text" className="form-control vega-form-control" readOnly/>
    </div>)
  }
}
