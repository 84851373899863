import React from 'react'
import Parse from 'parse'
import Container from './Container'
import RoleList from '../components/RoleList'

export default function RoleListContainer({orgUsername}) {
  function fetchData() {
    return Parse.Cloud.run("getOrgRoles",{orgUsername}).then((roles)=>({
      roles: roles.map((role)=>({
        id: role.id,
        name: role.get("name"),
        permissions: role.get("permissions").map((permission)=>({
          display: permission.get("display")
        })),
        isDefault: role.get("default")
      }))
    }))
  }
  return <Container fetchData={fetchData} component={RoleList} props={{orgUsername}}/>
}
