import React from 'react'
import Parse from 'parse'
import Container from './Container'
import EventList from '../components/EventList'

export default function OwnedEventsContainer({username}) {
  function fetchData() {
    return Parse.Cloud.run("getOwnedVegaEventsByUsername", {username})
      .then((events)=>({
        events:events.map((event)=>{
          const coverPhoto = event.get("coverPhoto")
          return {
            id: event.id,
            thumbnailSrc: coverPhoto ? coverPhoto.url() : null,
            title: event.get("title"),
            startTime: event.get("startTime"),
            endTime: event.get("endTime"),
            locationName: event.get("locationName"),
            about: event.get("about") || ""
          }
        })
      }))
  }

  return <Container fetchData={fetchData} component={EventList} props={{emptyMessage:"You haven't made any events"}} />
}
