import React from 'react'
import VegaTextBox from './VegaTextBox'

export default class VegaTextarea extends VegaTextBox {
  render() {
    return <div className="vega-form-group">
      <textarea
        id={this.props.id}
        value={this.props.form.state[this.props.id]}
        onChange={this.handleChange}
        placeholder={this.props.placeholder}
        className={`form-control vega-form-control
          ${this.state.validating ? "validating":""}
          ${this.state.validated ? "validated":""}
          ${this.state.error!==null ? "error":""}`}
        type={this.state.type}/>
      <span className="VegaTextBox-error">{this.state.error!==null ? VegaTextBox.getErrorText(this.state.error) : null}</span>
    </div>
  }
}
