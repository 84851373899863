import React from 'react'
import Parse from 'parse'
import Container from './Container'
import ManageOrganization from '../components/ManageOrganization'

const VegaOrganization = Parse.Object.extend("Organization")

export default function ManageOrganizationContainer({orgUsername}) {
  function fetchData(){
    return new Parse.Query(VegaOrganization)
  	 .equalTo("username", orgUsername)
     .first()
     .then((org)=>{
      let photo = org.get("coverPhoto")
      return {
        id: org.id,
        imgSrc : photo ? photo.url() : null,
        name : org.get("name"),
        subName : org.get("subName"),
        username : org.get("username"),
        about : org.get("about"),
      }
    })
  }

  return <Container fetchData={fetchData} component={ManageOrganization}/>
}
