import React from 'react'
import defaultImage from '../images/default.png';
import VegaLink from './VegaLink'

export default function NotificationCell({thumbnailSrc, message, action, read}) {
  return (<VegaLink to={action} noStyling>
    <div className={`card NotificationCell ${read ? "" : "unread"}`}>
      <div className="card-body">
        <div className="left-thumbnail">
          <img className="notification-cell-thumbnail" src={thumbnailSrc ? thumbnailSrc : defaultImage} alt=""/>
        </div>
        <div>
          <p>{message}</p>
        </div>
      </div>
    </div>
  </VegaLink>)
}
