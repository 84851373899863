import React, { Component } from 'react'

export default class LocationPickerModal extends Component {
  constructor(props) {
    super(props)

    this.handleCommitLocation = this.handleCommitLocation.bind(this)
  }

  componentDidMount() {
    window.addEventListener("message", this.handleCommitLocation)
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleCommitLocation)
  }

  handleCommitLocation(event) {
    if(typeof event.data==="string") {
      var data = JSON.parse(event.data)
      if(data.action==="commitLocation") {
        let resultLocation = JSON.parse(event.data).data
        this.props.commitLocation(resultLocation)
      }
    }
  }

  render() {
    return (<div className="modal-content">
      <div className="modal-body">
        <iframe
          id="LocationPickerOverlay"
          src={`${process.env.REACT_APP_MAPS_LOCATION_PICKER_URL}?JWT_TOKEN=${process.env.REACT_APP_MAPS_JWT_TOKEN}`}
          allow="geolocation"
          title="location picker"
          className="LocationPickerModal" />
      </div>
    </div>)
  }
}
