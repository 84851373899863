import React from 'react'
import Parse from 'parse'
import Container from './Container'
import OrgMembers from '../components/OrgMembers'

export default function OrgMembersContainer({orgUsername}) {
  function fetchData() {
    return Parse.Cloud.run("getOrgMemberships",{orgUsername}).then((members)=>{
      console.log(members)
      return {
        memberships:members.map((membership)=>{
          const member = membership.get("user")
          const profilePic = member.get("profilePic")
          return {
            thumbnailSrc: profilePic ? profilePic.url() : null,
            firstName: member.get("firstName"),
            lastName: member.get("lastName"),
            username: member.get("username"),
            roles: membership.get("roles").map((role)=>role.get("name")),
            accepted: membership.get("accepted")
          }
        })
      }
    })
  }
  return <Container fetchData={fetchData} component={OrgMembers} props={{orgUsername}}/>
}
