import Parse from 'parse'

export default class ParseUtil {
  static initialize() {
    Parse.initialize(process.env.REACT_APP_PARSE_APP_ID);
    Parse.serverURL = process.env.REACT_APP_PARSE_URL;

    Parse.User.enableRevocableSession();
    Parse.User.enableUnsafeCurrentUser();
  }

  static async isSignedIn() {
    const currUserExists = Parse.User.current() ? true : false; //XXX: maybe there is a more semantic way to do this?
    if(!currUserExists) {
      await Parse.AnonymousUtils.logIn()
    }

    const currUser = Parse.User.current()
    return !Parse.AnonymousUtils.isLinked(currUser)
  }

  static userSubscriptions = []

  static subscribeToUserChange(callback) {
    ParseUtil.userSubscriptions.push(callback)
  }

  static async handleUserChange() {
    const isSignedIn = await ParseUtil.isSignedIn()
    ParseUtil.userSubscriptions.forEach((func)=>{
      func(isSignedIn)
    })
  }

  static logIn(username, password) {
    return Parse.User.logIn(username, password).then((user)=>{
      //console.log("Signed in user:",user)
      ParseUtil.handleUserChange()
      return user
    }).catch((error)=>{
      switch (error.code) {
        case 101:
          throw String("Invalid username/password")
        default:
          throw String("Unknown error")
      }
    })
  }

  static signUp(values) {
    var user = new Parse.User()
    user.setUsername(values.username)
    user.setPassword(values.password)
    user.set("firstName",values.firstName)
    user.set("lastName",values.lastName)
    user.set("email",values.email)
    return user.signUp().then((repsonse)=>{
      ParseUtil.handleUserChange()
    })
  }

  static handleInvalidSessionToken() {
    console.log("Handled invalid session token")
    ParseUtil.logOut()
  }

  static logOut() {
    Parse.User.logOut().then(()=>{
      ParseUtil.handleUserChange()
    })
  }
}
