import React, { Component } from 'react'
import Datetime from 'react-datetime'

export default class VegaDatetime extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      validating: false,
      validated: true,
      error: null
    }
    props.form.handleValidationChange(props.id,this.state.validated)
  }

  handleChange(datetime) {
    if(!datetime.isValid || !datetime.isValid()) {
      this.setState({validating:false,validated:false,error:"This is not a valid date."})
      return
    }
    this.setState({validating:false,validated:true,error:null})
    this.props.form.onTimeChange(this.props.id,datetime)
  }

  static getErrorText(error) {
    return error
  }

  render() {
    return <div className="w-100 vega-form-group">
      <Datetime
        value={this.props.form.state[this.props.id]}
        onChange={this.handleChange}
        inputProps={{
          placeholder:this.props.placeholder,
          className:`form-control vega-form-control
            ${this.state.validating ? "validating":""}
            ${this.state.validated ? "validated":""}
            ${this.state.error!==null ? "error":""}`
        }}/>
      <span className="VegaTextBox-error">{this.state.error!==null ? VegaDatetime.getErrorText(this.state.error) : null}</span>
    </div>
  }
}
