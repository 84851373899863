import React from 'react'
import { Link } from 'react-router-dom'
import OrganizationCell from './OrganizationCell'

export default function OrganizationList({orgs,emptyMessage}) {
  return orgs.length===0
  ? (<div>
    <p className="list-message">{emptyMessage}</p>
    <Link to="/orgs/create"><i className="fas fa-plus"/> Register Organization</Link>
  </div>)
  : (<div>
    {orgs.map((org, i)=><OrganizationCell key={i} {...org}/>)}
    <Link to="/orgs/create"><i className="fas fa-plus"/> Register Organization</Link>
  </div>)
}
