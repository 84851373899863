import React from 'react'
//import Parse from 'parse'
import VegaLink from '../VegaLink'

export default function NavList({isSignedIn}) {
  //const currUsername = Parse.User.current().get("username")
  return (<span className="NavList">
    {/*<NavOption text="Home Feed" to="/events"/>*/}
    <NavOption text="Notifications" to="/notifications"/>
    <NavOption text="Saved Events" to="/events/saved"/>
    {/*<NavOption text="My Profile" to={`/user/${currUsername}`}/>*/}
    <NavOption text="Organizations" to="/orgs"/>
    {/*<NavOptionHidden text="View Event" to="/event/:eventId"/>
    <NavOptionHidden text="Create Organization" to="/orgs/create"/>
    <NavOptionHidden text="Manage Organization" to="/org/:username"/>
    <NavOptionHidden text="Stars Actions" to="/org/:username/stars/actions"/>
    <NavOptionHidden text="Stars Deals" to="/org/:username/stars/deals"/>*/}
    <NavOption text="Create Event" to="/events/create"/>
  </span>)
}

function NavOption({to, text}) {
  return (<VegaLink to={to} highlightActive>{text}</VegaLink>)
}
