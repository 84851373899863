import React from 'react'
import MemberList from './MemberList'
import InviteUser from '../forms/InviteUser'

export default function OrgMembers({orgUsername,memberships,refreshContainer}) {
  return <div className="OrgMembers">
    <h3>Members</h3>
    <MemberList memberships={memberships}/>
    <InviteUser orgUsername={orgUsername} refreshMembers={refreshContainer}/>
  </div>
}
