import React from 'react'
import VegaLink from './VegaLink'

export default function ManageOrganization({id, imgSrc, name, subName, username, about}) {
  return (<div id={id} className="ManageOrganization card">
    {imgSrc ? <img className="card-img-top" src={imgSrc} alt={name + " image"}/>:null}
    <div className="card-body">
      <h2>{name}</h2>
      <h4>{subName}</h4>
      <p>@{username}</p>
      <div>{about.split("\n").map((p,i)=><p key={i}>{p}</p>)}</div>
      <hr/>
      <div className="d-flex w-100">
        <VegaLink to={`/org/${username}/events`} className="w-100 text-center">Events</VegaLink>
        <VegaLink to={`/org/${username}/members`} className="w-100 text-center">Membership</VegaLink>
        <VegaLink to={`/org/${username}/roles`} className="w-100 text-center">Roles</VegaLink>
      </div>
    </div>
  </div>)
}
