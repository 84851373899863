import React, { Component } from 'react'
import InputRange from 'react-input-range'
import 'react-input-range/lib/css/index.css'
import SettingsUtil from '../js/SettingsUtil'
import LocationPicker from './LocationPicker'

export default function DiscoverySettings() {
  return <div className="card DiscoverySettings">
    <div className="card-body">
      <SortButtons/>
      <br/>
      <div className="DiscoverySettings-label">Searching near</div>
      <LocationPicker onChange={onLocationChange}/>
      <DistancePicker/>
    </div>
  </div>
}

function onLocationChange(location) {
  SettingsUtil.setSettings({location})
}

class SortButtons extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sortType: SettingsUtil.settings.sortType
    }

    this.setSortType = this.setSortType.bind(this)
  }

  setSortType(sortType) {
    SettingsUtil.setSettings({sortType})
    this.setState({sortType})
  }

  render() {
    const sortType = this.state.sortType
    return (<div className="d-flex text-center">
      <span onClick={()=>this.setSortType(0)} className={`sort-button w-100 ${sortType===0?"active":""}`}>Hot</span>
      <span onClick={()=>this.setSortType(1)} className={`sort-button w-100 ${sortType===1?"active":""}`}>Soon</span>
      <span onClick={()=>this.setSortType(2)} className={`sort-button w-100 ${sortType===2?"active":""}`}>New</span>
    </div>)
  }
}

class DistancePicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: SettingsUtil.settings.radiusValue
    }

    this.onChange = this.onChange.bind(this)
    this.onChangeComplete = this.onChangeComplete.bind(this)
  }

  onChange(value) {
    this.setState({value})
  }

  onChangeComplete(value) {
    this.setState({value})
    const radius = DistancePicker.radiusFromValue(value)
    SettingsUtil.setSettings({radiusValue:value,radius})
  }

  static ticks = [0.1,0.25,0.5,0.75,1,2,3,4,5,10,15,20,25,50,75,100,250,500,1000,1000000]

  static radiusFromValue(value) {
    return DistancePicker.ticks[value]
  }

  static getDisplayFromValue(value) {
    if(value===19) {
      return "Unlimited"
    } else {
      return DistancePicker.radiusFromValue(value) + " mi"
    }
  }

  render() {
    return <div className='slider'>
      <div className="DiscoverySettings-label">
        <span>Radius</span>
        <span className="float-right">{DistancePicker.getDisplayFromValue(this.state.value)}</span>
      </div>
      <InputRange
        minValue={0}
        maxValue={19}
        formatLabel={DistancePicker.getDisplayFromValue}
        value={this.state.value}
        onChange={this.onChange}
        onChangeComplete={this.onChangeComplete}
      />
    </div>
  }
}
