import React from 'react'
import Parse from 'parse'
import validator from 'validator'
import ParseUtil from '../js/ParseUtil'
import ModalManager from './ModalManager'
import FormComponent from '../forms/FormComponent'
import VegaTextBox from '../components/VegaTextBox'
import vega_logo from '../images/vega_logo.png'

export default class SignUpModal extends FormComponent {
  static fields = {
    firstName: "TEXT",
    lastName: "TEXT",
    email: "TEXT",
    username: "TEXT",
    password: "TEXT",
  }

  constructor(props) {
    super(props, SignUpModal)

    this.state.step = 0
    this.nextStep = this.nextStep.bind(this)
  }

  nextStep() {
    this.setState({
      step: this.state.step+1
    })
  }

  static validateEmail(value) {
    if(!validator.isEmail(value)) {
      throw String("Not a valid email")
    }
    return Parse.Cloud.run("validateEmail", {email:value}).then((responseCode)=>{
      switch (responseCode) {
        case "available":
          return true
        case "unavailable":
          throw String("This email is taken")
        case "invalidFormat":
          throw String("Not a valid email")
        default:
          throw String("UNKNOWN_REPONSE_CODE: please contact Vega development")
      }
    })
  }

  static validatePassword(value) {
    if(value.length<8) {
      throw String("Passwords must be at least 8 characters long")
    }
    return true
  }

  static validateUsername(value) {
    if(value.length<3) {
      throw String("Usernames must be at least 3 characters")
    }
    if(value.length>30) {
      throw String("Username cannot be longer than 30 characters")
    }
    return Parse.Cloud.run("validateUsername", {username:value}).then((responseCode)=>{
      switch (responseCode) {
        case "tooShort":
          throw String("Username is too short")
          case "available":
            return true
          case "unavailable":
            throw String("This username is taken")
        default:
          throw String("Unknown error")
      }
    })
  }

  onSave() {
    this.signUp()
    ModalManager.current.hide("SignUp")
  }

  async signUp() {
    const values = await this.getFieldValues()
    ParseUtil.signUp(values)
  }

  render() {
    return <div id="SignUpModal">
      {this.state.step===0 && <SignUpModalInitialScreen signUp={this} valid={this.allValid(["firstName","lastName","email","password"])}/>}
      {this.state.step===1 && <SignUpModalUsernameScreen signUp={this} valid={this.allValid(["username"])}/>}
    </div>
  }
}

function SignUpModalInitialScreen({signUp, valid}) {
  return <div className="modal-content">
    <div className="modal-body">
      <div className="w-100 text-center">
        <img className="signup-vega-logo" src={vega_logo} alt="vega logo"/>
        <h3>Sign Up!</h3>
      </div>
      <div className="row">
        <div className="col-6">
          <VegaTextBox id="firstName" placeholder="First Name" form={signUp}/>
        </div>
        <div className="col-6">
          <VegaTextBox id="lastName" placeholder="Last Name" form={signUp}/>
        </div>
      </div>
      <VegaTextBox id="email" validate={SignUpModal.validateEmail} placeholder="Email" form={signUp}/>
      <VegaTextBox id="password" validate={SignUpModal.validatePassword} placeholder="Password" type="password" form={signUp}/>
      {valid ? <div onClick={signUp.nextStep} className="VegaLink text-center w-100">Finish Sign Up</div> : null}
    </div>
  </div>
}

function SignUpModalUsernameScreen({signUp}) {
  return <div className="modal-content">
    <div className="modal-body">
      <h4>Lastly, create a unique username.</h4>
      <p>This is how others can find you on Vega.</p>
      <VegaTextBox id="username" validate={SignUpModal.validateUsername} placeholder="Username" form={signUp}/>
      <div onClick={signUp.onSave} className="VegaLink text-center w-100">Sign Up!</div>
    </div>
  </div>
}
