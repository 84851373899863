import React from 'react'
import ParseUtil from '../js/ParseUtil'
import ModalManager from './ModalManager'
import FormComponent from '../forms/FormComponent'
import VegaTextBox from '../components/VegaTextBox'
import vega_logo from '../images/vega_combined_white.jpg'

export default class LogInModal extends FormComponent {
  static fields = {
    username: "TEXT",
    password: "TEXT",
  }

  constructor(props) {
    super(props, LogInModal)

    this.state.error = null
  }

  onSave() {
    this.logIn()
  }

  async logIn() {
    this.setState({error:null})
    const values = await this.getFieldValues()
    ParseUtil.logIn(values.username,values.password).then((response)=>{
      ModalManager.current.hide("LogIn")
    }).catch((errorMsg)=>{
      this.setState({error:errorMsg})
    })
  }

  render() {
    return <div id="LogInModal">
      <div className="modal-content">
        <div className="modal-body">
          <div className="text-center">
            <img className="login-vega-logo" src={vega_logo} alt="vega logo"/><br/>
            <VegaTextBox id="username" form={this} placeholder="Username"/>
            <VegaTextBox id="password" form={this} placeholder="Password" type="password"/>
            <span className="form-error">{this.state.error}</span><br/>
            {this.allValid() ? <div onClick={this.onSave} className="VegaLink text-center w-100">Login</div> : null}
          </div>
        </div>
      </div>
    </div>
  }
}
