import React from 'react'
import Parse from 'parse'
import SettingsUtil from '../js/SettingsUtil'
import Container from './Container'
import HomeFeed from '../components/HomeFeed'

export default function HomeFeedContainer() {
  return <Container fetchData={fetchData} component={HomeFeed}/>
}

function fetchData() {
  return Parse.Cloud.run("getHomeFeedEvents", {
    latitude : SettingsUtil.settings.location.coordinate.latitude,
    longitude : SettingsUtil.settings.location.coordinate.longitude,
    radius : SettingsUtil.settings.radius,
    sortType : SettingsUtil.settings.sortType,
    categories : [],
  }).then((events)=>({
    events: events.map((event)=>{
      const coverPhoto = event.get("coverPhoto")
      return {
        id: event.id,
        title: event.get("title"),
        thumbnailSrc: coverPhoto ? coverPhoto.url() : null,
        startTime: event.get("startTime"),
        locationName: event.get("location").get("name"),
        about: event.get("about")
      }
    })
  }))
}
