import React from 'react'
import moment from 'moment'

export default class DateUtil {
  static getDateRangeString(startDatetime,endDatetime) {
    startDatetime = moment(startDatetime)
    endDatetime = moment(endDatetime)

    const startDate = DateUtil.stripTime(startDatetime)
    const endDate = DateUtil.stripTime(endDatetime)
    if(startDate.diff(endDate)===0) {
      return [
        startDatetime.format("dddd, MMMM D"),
        <br key={0}/>,
        DateUtil.displayTime(startDatetime)+" - "+DateUtil.displayTime(endDatetime)
      ]
    } else {
      return [
        startDatetime.format("dddd, MMMM D")+" - "+endDatetime.format("dddd, MMMM D"),
        <br key={0}/>,
        DateUtil.displayTime(startDatetime)+" - "+DateUtil.displayTime(endDatetime)
      ]
    }
  }

  static getDateStringBrief(datetime) {
    datetime = moment(datetime)

    const now = moment()
    if(datetime.diff(now) <= 0) {
      return "Happening now!"
    }

    const date = DateUtil.stripTime(datetime)

    const today = DateUtil.stripTime(moment())

    if(date.diff(today) <= 0) {
      return "Today at " + DateUtil.displayTime(datetime)
    }

    const tomorrow = DateUtil.stripTime(moment().add(1,'days'))
    if(date.diff(tomorrow) <= 0) {
      return "Tomorrow at " + DateUtil.displayTime(datetime)
    }

    const endOfWeek = DateUtil.stripTime(moment().add(6,'days'))
    if(date.diff(endOfWeek) <= 0) {
      return datetime.format("dddd")
    }

    return datetime.format("dddd, MMMM D")
  }

  static displayTime(datetime) {
    const anyMinutes = (datetime.minutes()!==0)
    return datetime.format(`h${anyMinutes?":mm":""} A`)
  }

  static stripTime(date){
    date = moment(date);
    date.hours(0);
    date.minutes(0);
    date.seconds(0);
    date.milliseconds(0);
    return date;
  }
}
