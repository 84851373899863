import React from 'react'
import Parse from 'parse'
import Container from './Container'
import OrganizationList from '../components/OrganizationList'

export default function MyOrganizationsContainer() {
  return <Container fetchData={fetchData} component={OrganizationList} props={{emptyMessage:"You do not administrate any Organizations."}}/>
}

function fetchData() {
  return Parse.Cloud.run("getOrgsForUser").then(
    (orgs)=>({orgs: orgs.map((org)=>{
      const coverPhoto = org.get("coverPhoto")
      return {
        id: org.id,
        name: org.get("name"),
        subName: org.get("subName"),
        username: org.get("username"),
        thumbnailSrc: coverPhoto ? coverPhoto.url() : null
      }
    })})
  )
}
