import React from 'react'
import { matchPath, withRouter } from 'react-router-dom'

function VegaLink({to,highlightActive,noStyling,className,children,location,history}) {
  const isMatch = matchPath(location.pathname, {
    path: to,
    exact: true,
    strict: false
  }) !== null
  return (<span className={`${noStyling ? "VegaLink-noStyling" : "VegaLink"} ${(highlightActive && isMatch) ? "active" : ""} ${className}`} onClick={()=>history.push(to)}>
    {children}
  </span>)
}

export default withRouter(VegaLink)
