import React from 'react';
import ParseUtil from '../js/ParseUtil'
import OwnedEventsContainer from '../containers/OwnedEventsContainer';
import defaultImage from '../images/default.png';

export default function Profile({profilePicSrc, username, firstName, lastName, bio}) {
  return (
    <div className="Profile">
      <div className="card">
        <div className="card-body">
          <div>
            <span onClick={ParseUtil.logOut} className="VegaLink float-right">Log Out</span>
          </div><br/>
          <div className="text-center">
            <img className="profile-pic w-50" src={profilePicSrc ? profilePicSrc : defaultImage} alt="profile" /><br/>
            <h2>{`${firstName} ${lastName}`}</h2>
            <p>{bio}</p>
            <br/>
          </div>
        </div>
      </div>
      <OwnedEventsContainer username={username}/>
    </div>
  );
}
