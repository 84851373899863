import React from 'react'
import Parse from 'parse'
import FormComponent from './FormComponent'
import LocationPicker from '../components/LocationPicker'
import VegaTextBox from '../components/VegaTextBox'
import VegaTextarea from '../components/VegaTextarea'
import VegaDatetime from '../components/VegaDatetime'
import VegaImagePicker from '../components/VegaImagePicker'

import "../css/react-datetime.css"
//import moment from 'moment'
//import PosterRec from '../js/PosterRec_asm';

const MODE_NEW = "new"

export default class EditEventInfo extends FormComponent {
  static fields = {
    "coverPhoto":"IMAGE",
    "title":"TEXT",
    "location":"LOCATION",
    "organizationName":"TEXT",
    "startTime":"DATE",
    "endTime":"DATE",
    "placeName":"TEXT",
    "about":"TEXT"
  }

  constructor(props) {
    super(props, EditEventInfo);
  }



  /*posterRec_test(){
    var img = new Image();
    img.src = "/splash.jpg";
    img.onload = function (){
      var canvas = document.getElementById("poster-rec-canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      var imageData = ctx.getImageData(0,0,canvas.width,canvas.height);

      var boxes = window.detectText(imageData);

      //for(var i=0; i<boxes.length; i++){
      //  var box=boxes[i];
      //  ctx.strokeRect(box.x, box.y, box.w, box.h);
      //}
      window.recognizeText(img, boxes, canvas, function(texts) {
        window.categorizeText(texts, boxes);
      })
    }
  }*/

  async onSave() {
    Parse.Cloud.run("editEvent",{
      mode: MODE_NEW,
      fieldsChanged: await this.getFieldValues()
    }).then((event)=>{
      this.props.history.push(`/event/${event.id}`)
    })
  }

  render() {
    return (
      <div className="EditEventInfo card">
        <VegaImagePicker id="coverPhoto" form={this}/>
        <div className="card-body">
          <VegaTextBox id="title" form={this} placeholder="Title"/>
          <LocationPicker id="location" form={this}/>
          <VegaTextBox id="organizationName" form={this} placeholder="Hosted by (i.e., club name)"/>
          <div className="d-flex">
            <VegaDatetime id="startTime" form={this} placeholder="Starts"/>
            <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>
            <VegaDatetime id="endTime" form={this} placeholder="Ends"/>
          </div>
          <VegaTextarea id="about" form={this} placeholder="About"/>
          <div onClick={this.onSave} className="VegaLink text-center w-100">Save</div>
        </div>
      </div>
    );
  }
}
