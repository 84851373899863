import React from 'react'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import VegaLink from '../VegaLink'
import NavList from './NavList'

import defaultImage from '../../images/default.png'
import vegaIcon from '../../images/vega_combined.jpg'

function Header({profilePicSrc, firstName, lastName, username, location, history}) {
  function onSearchTextChange(e) {
    //const searchText = e.target.value
    //TODO: autocomplete
  }

  function searchKeyPressed(e){
    if(e.charCode === 13){ //ENTER
      const searchText = e.target.value
      const to = `/search?searchText=${searchText}`
      history.push(to)
    }
  }

  const defaultSearchText = queryString.parse(location.search).searchText || ""

  return <div className="fixed-top">
    <nav className="Header navbar navbar-inverse">
      <div className="container">
        <span>
          <VegaLink to="/events">
            <img src={vegaIcon} className="header-pic ml-lg-2 mx-3" alt=""/>
          </VegaLink>
          <NavList isSignedIn={true}/>
        </span>
        <span>
          <div id="searchbar" className="form-group has-search">
            <span className="fa fa-search form-control-feedback"></span>
            <input type="text" defaultValue={defaultSearchText} className="form-control" placeholder="Search Vega" onKeyPress={searchKeyPressed} onChange={onSearchTextChange}/>
          </div>
        </span>
        <span className="navbar-text"><VegaLink to={`/user/${username}`}>
          <img className="header-profile-pic align-middle" src={profilePicSrc ? profilePicSrc : defaultImage} alt="profile pic"/>&nbsp;
          <span>{`${firstName} ${lastName}`}</span>
        </VegaLink></span>
      </div>
    </nav>
  </div>
}

export default withRouter(Header)
