import { Component } from 'react'
import Parse from 'parse'

export default class FormComponent extends Component {
  constructor(props, subClass) {
    super(props)

    this.fields = subClass.fields
    this.saveFunction = subClass.saveFunction

    this.state = {
      saved: props.saved || false,
      fieldsValid: {}
    }
    for(var field in this.fields){
      if(this.props[field]===undefined){
        let value=null
        switch (this.fields[field]) {
          case "TEXT":
            value=""
            break;
          case "DATE":
            value=new Date()
            break;
          case "SELECT":
            // Must be defined in the subclass
            break;
          case "NUMBER":
            value=0
            break
          case "IMAGE":
            value=null
            break;
          case "LOCATION":
            value=null
            break;
          default:
            value=null
            break;
        }
        this.state[field] = value
      } else {
        this.state[field] = this.props[field]
      }
      this.state.fieldsValid[field] = false
    }

    //this.VegaObject = Parse.Object.extend(className)

    this.onSave = this.onSave.bind(this)
    this.onTextChange = this.onTextChange.bind(this)
    this.onNumberChange = this.onNumberChange.bind(this)
    this.onSelectChange = this.onSelectChange.bind(this)
    this.onCheckChange = this.onCheckChange.bind(this)
    this.onImageChange = this.onImageChange.bind(this)
    this.onTimeChange = this.onTimeChange.bind(this)
    this.onLocationChange = this.onLocationChange.bind(this)
    this.handleValidationChange = this.handleValidationChange.bind(this)
    this.allValid = this.allValid.bind(this)
  }

  onTextChange(e) {
    var newState = {saved: false};
    newState[e.target.id] = e.target.value;
    this.setState(newState);
  }

  onNumberChange(e) {
    const value = parseFloat(e.target.value) || ""
    var newState = {saved: false};
    newState[e.target.id] = value;
    this.setState(newState);
  }

  onSelectChange(e) {
      var newState = {saved: false};
      newState[e.target.id] = e.target.value;
      this.setState(newState);
  }

  onCheckChange(e) {
    var newState = {saved: false};
    newState[e.target.id] = e.target.checked;
    this.setState(newState);
  }

  onImageChange(e) {
    var thisComponent = this;

    let targetName = e.target.id

    if (e.target.files && e.target.files[0]) {
      let newFileState = {saved: false}
      newFileState[targetName+"_file"] = e.target.files[0]
      newFileState[targetName+"_parseFile"] = new Parse.File(targetName+".jpeg", e.target.files[0], "image/jpeg");
      thisComponent.setState(newFileState);

      var reader = new FileReader();

      reader.onload = function(e) {
        let newSrcState = {}
        newSrcState[targetName+"_src"] = e.target.result
        thisComponent.setState(newSrcState);
      }

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  onTimeChange(id, datetime) {
    var newState = {saved: false};
    newState[id] = datetime.toDate();
    this.setState(newState);
  }

  onLocationChange(id, location) {
    var newState = {saved: false};
    newState[id] = location;
    this.setState(newState);
  }

  handleValidationChange(id,valid) {
    let obj = {}
    obj[id] = valid
    this.setState({fieldsValid:Object.assign(this.state.fieldsValid,obj)})
  }

  allValid(fields) {
    fields = fields || Object.keys(this.fields)
    return fields.every((field)=>(
      this.state.fieldsValid[field]
    ))
  }

  async getFieldValues() {
    var fieldsChanged = {}
    for(var field in this.fields) {
      if(this.fields[field]==="IMAGE"){
        if(this.state[field+"_parseFile"]){
          fieldsChanged[field] = await this.state[field+"_parseFile"].save()
        }
      } else {
        fieldsChanged[field] = this.state[field]
      }
    }
    return fieldsChanged
  }
}
