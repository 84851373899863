import React from 'react'
import Parse from 'parse'
import Container from './Container'
import NotificationList from '../components/NotificationList'

export default function NotificationsContainer() {
  return <Container fetchData={fetchData} component={NotificationList}/>
}

function fetchData(){
  return Parse.Cloud.run("getNotifications",{})
    .then((notifications)=>({
        notifications: notifications.map((notification)=>{
        const image = notification.get("image")
        return {
          thumbnailSrc: image ? image.url() : null,
          message: notification.get("message"),
          action: notification.get("action"),
          read: notification.get("read")
        }
      })
    }))
}
