import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import ParseUtil from '../../js/ParseUtil'
import Home from './Home'

class Desktop extends Component {
  constructor(props) {
    require('../../css/desktop.css')

    super(props);

    this.state = {
      isSignedIn: false
    }

    ParseUtil.subscribeToUserChange((isSignedIn)=>{
      this.setState({isSignedIn})
      this.props.history.push("/")
    })
  }

  componentDidMount() {
    ParseUtil.isSignedIn().then((isSignedIn)=>{
      this.setState({isSignedIn})
    })
  }

  render() {
    return <Home isSignedIn={this.state.isSignedIn}/>
  }
}

export default withRouter(Desktop)
