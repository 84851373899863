import React from 'react';
import EventCell from './EventCell';
//import EventListHeader from './EventListHeader';

export default function EventList({events,emptyMessage}) {
  return (<div className="EventList">
    {events.length===0
      ? <p className="list-message">{emptyMessage}</p>
      : (<div>
          {events.map((event, i)=><EventCell key={i} {...event}/>)}
        </div>)
    }
  </div>)
}
