import React, { Component } from 'react'

export default class VegaDatetime extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(datetime) {
    if(!datetime.isValid || !datetime.isValid()) {
      this.setState({validating:false,validated:false,error:"This is not a valid date."})
      return
    }
    this.setState({validating:false,validated:true,error:null})
    this.props.form.onTimeChange(this.props.id,datetime)
  }

  render() {
    if(this.props.form.state[this.props.id + "_src"]){
      return <div className="w-100 vega-form-group mb-0">
        <img className="card-img-top" src={this.props.form.state[this.props.id+"_src"]} alt={this.props.form.state.title + " image"}/>
        <label htmlFor={this.props.id} className="edit-pic-label VegaLink">
          <i className="fas fa-camera"/>&nbsp;Edit Photo
        </label>
        <input id={this.props.id} onChange={this.props.form.onImageChange} name={this.props.id} type="file"/><br/>
      </div>
    } else {
      return <div className="w-100 vega-form-group mb-0">
        <label htmlFor={this.props.id} className="edit-pic-label VegaLink">
          <i className="fas fa-camera"/>&nbsp;Add Photo
        </label>
        <input id={this.props.id} onChange={this.props.form.onImageChange} name={this.props.id} type="file"/><br/>
      </div>
    }
  }
}
