import React from 'react'
import VegaLink from './VegaLink'
import AddRole from '../forms/AddRole'

export default function RoleList({orgUsername,roles,refreshContainer}) {
  return <div className="RoleList">
    <div>
      {roles.map((role, i)=><RoleCell key={i} {...role} orgUsername={orgUsername}/>)}
    </div>
    <AddRole orgUsername={orgUsername} refreshRoles={refreshContainer}/>
  </div>
}

function RoleCell({id,orgUsername,name,permissions,isDefault}) {
  const joinedPermission = permissions.map((permission)=>(permission.display)).join(", ")
  return <div className="RoleCell card">
    <div className="card-body">
      <div className="float-left">
        <h4>{name}</h4>
        <span>{joinedPermission}</span>
      </div>
      <div className="float-right">
        {isDefault ? null : <VegaLink to={`/org/${orgUsername}/role/${id}`}><i className="fas fa-15x fa-edit"/></VegaLink>}
      </div>
    </div>
  </div>
}
