import React from 'react'
import Parse from 'parse'
import Container from './Container'
import Profile from '../components/Profile'

export default function ProfileContainer({username}){

  function fetchData(){
    var query = new Parse.Query(Parse.User).equalTo("username", username)
    return query.first().then((user)=>{
      let profilePic = user.get("profilePic")
      return {
        profilePicSrc: profilePic ? profilePic.url() : null,
        firstName: user.get("firstName"),
        lastName: user.get("lastName"),
        bio: user.get("bio"),
        username: username,
      }
    })
  }

  return <Container fetchData={fetchData} component={Profile}/>
}
