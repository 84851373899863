import React from 'react'
import defaultImage from '../images/default.png';

export default function UserCell({thumbnailSrc, firstName, lastName, username, roles, accepted}) {
  return (<div className="card"><div className="card-body">
    <div className="">
      <div className="left-thumbnail">
        <img className="user-cell-thumbnail" src={thumbnailSrc ? thumbnailSrc : defaultImage} alt="profile pic"/>
      </div>
      <div>
        <h6>{firstName} {lastName}</h6>
        <div className={accepted?"":"text-muted"}>{accepted ? roles.join(", ") : "INVITED"}</div>
      </div>
    </div>
  </div></div>)
}
