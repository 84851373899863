import React from 'react'
import Parse from 'parse'
import queryString from 'query-string'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'

import ModalManager from '../../modals/ModalManager'
import SignUpModal from '../../modals/SignUpModal'
import LogInModal from '../../modals/LogInModal'
import LocationPickerModal from '../../modals/LocationPickerModal'

//import NavList from './NavList'
import HeaderContainer from '../../containers/desktop/HeaderContainer'
import HeaderAnon from './HeaderAnon'
import HomeFeedContainer from '../../containers/HomeFeedContainer'
import EditEventInfo from '../../forms/EditEventInfo'
import SavedEventsContainer from '../../containers/SavedEventsContainer'
import ProfileContainer from '../../containers/ProfileContainer'
import MyOrganizationsContainer from '../../containers/MyOrganizationsContainer'
import NotificationsContainer from '../../containers/NotificationsContainer'
import EventInfoContainer from '../../containers/EventInfoContainer'
import CreateOrganization from '../../forms/CreateOrganization'
import ManageOrganizationContainer from '../../containers/ManageOrganizationContainer'
//import ManageStarsActionsContainer from '../../containers/ManageStarsActionsContainer'
//import ManageStarsDealsContainer from '../../containers/ManageStarsDealsContainer'
//import StarsEventConfigContainer from '../../containers/StarsEventConfigContainer'
//import CompleteStarsActionsContainer from '../../containers/CompleteStarsActionsContainer'
//import CompleteStarsActionContainer from '../../containers/CompleteStarsActionContainer'
import OrgMembersContainer from '../../containers/OrgMembersContainer'
import RoleListContainer from '../../containers/RoleListContainer'
import EditRoleContainer from '../../containers/EditRoleContainer'
import SearchContainer from '../../containers/SearchContainer'
import OrgInviteContainer from '../../containers/OrgInviteContainer'

import UnderConstruction from '../UnderConstruction'

import DiscoverySettings from '../DiscoverySettings'

function Home({location,isSignedIn}) {
  const currUsername = isSignedIn ? Parse.User.current().get("username") : undefined
  const params = queryString.parse(location.search)
  return (
    <div className="Home-desktop">
      {isSignedIn ? <HeaderContainer/> : <HeaderAnon/>}
      <div id="main-container">
        <div className="row">
          <div className="col-md-2 col-lg-3 col-xl-3 fixed">
            <Switch>
              <Route path="/events" component={DiscoverySettings} exact/>
            </Switch>
          </div>
          <div className="offset-md-2 offset-lg-3 offset-xl-3 col-md-8 col-lg-6 col-xl-6">
            <Switch>
              <Redirect from="/" to="/events" exact/>
              <Route path="/events" component={HomeFeedContainer} exact/>
              <Route path="/events/create" component={EditEventInfo} exact/>
              <Route path="/events/saved" component={SavedEventsContainer} exact/>
              { isSignedIn ? <Route path={`/user/${currUsername}`} render={(props)=><ProfileContainer {...props} username={currUsername}/>} exact/> : null }
              <Route path="/notifications" component={NotificationsContainer} exact/>
              <Route path="/orgs" component={MyOrganizationsContainer} exact/>
              <Route path="/event/:eventId" children={({match})=><EventInfoContainer {...match.params}/>} exact/>
              <Route path="/orgs/create" component={CreateOrganization} exact/>
              <Route path="/org/:orgUsername" children={({match})=><ManageOrganizationContainer {...match.params}/>} exact/>
              {/*<Route path="/org/:orgUsername/stars/actions" children={({match})=><ManageStarsActionsContainer {...match.params}/>} exact/>*/}
              {/*<Route path="/org/:orgUsername/stars/deals" children={({match})=><ManageStarsDealsContainer {...match.params}/>} exact/>*/}
              {/*<Route path="/event/:eventId/stars/config" children={({match})=><StarsEventConfigContainer {...match.params}/>} exact/>*/}
              {/*<Route path="/event/:eventId/stars/actions" children={({match})=><CompleteStarsActionsContainer {...match.params}/>} exact/>*/}
              {/*<Route path="/event/:eventId/stars/action/:actionId" children={({match})=><CompleteStarsActionContainer {...match.params}/>} exact/>*/}
              <Route path="/org/:orgUsername/members" children={({match})=><OrgMembersContainer {...match.params}/>} exact/>
              <Route path="/org/:orgUsername/roles" children={({match})=><RoleListContainer {...match.params}/>} exact/>
              <Route path="/org/:orgUsername/role/:roleId" children={({match})=><EditRoleContainer {...match.params}/>} exact/>
              <Route path="/search" render={()=><SearchContainer searchText={params.searchText}/>} exact/>
              <Route path="/org/:orgUsername/invite" children={({match})=><OrgInviteContainer {...match.params}/>} exact/>
              <Route component={UnderConstruction}/>
            </Switch>
          </div>
        </div>
      </div>
      <ModalManager>
        <SignUpModal id="SignUp"/>
        <LogInModal id="LogIn"/>
        <LocationPickerModal id="LocationPicker"/>
      </ModalManager>
    </div>
  )
}

export default withRouter(Home)
