import React from 'react'
import Parse from 'parse'
import Container from './Container'
import EditRoleForm from '../forms/EditRoleForm'

export default function({orgUsername,roleId}) {
  function fetchData() {
    return Promise.all([
      Parse.Cloud.run("getRole",{roleId}),
      Parse.Cloud.run("getAllPermissions")
    ]).then(([role,allPermissions])=>({
      role: {
        id: role.id,
        name: role.get("name"),
        permissions: role.get("permissions").map((permission)=>({
          id: permission.id,
          name: permission.get("name")
        }))
      },
      allPermissions: allPermissions.map((permission)=>({
        id: permission.id,
        name: permission.get("name"),
        display: permission.get("display")
      }))
    }))
  }
  return <Container fetchData={fetchData} component={EditRoleForm} props={{orgUsername}}/>
}
