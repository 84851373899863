import React from 'react'
import Parse from 'parse'
import { withRouter } from 'react-router-dom'
import FormComponent from './FormComponent'

class EditRoleForm extends FormComponent {
  constructor(props) {

    EditRoleForm.fields = {}
    props.allPermissions.forEach((permission)=>{
      EditRoleForm.fields[permission.id] = "BOOL"
    })

    super(props,EditRoleForm)

    this.state = {}
    props.allPermissions.forEach((permission)=>{
      this.state[permission.id] = props.role.permissions
        .map((rolePerm)=>(rolePerm.id)).includes(permission.id)
    })

    this.comparePermissions = this.comparePermissions.bind(this)
  }

  onSave() {
    const rolePermissions = this.props.allPermissions
      .map((permission)=>permission.id)
      .filter((permId)=>this.state[permId])
    Parse.Cloud.run("setRolePermissions",{
      roleId:this.props.role.id,
      permIds:rolePermissions
    }).then(()=>{
      this.props.history.push(`/org/${this.props.orgUsername}/roles`)
    })
  }

  comparePermissions(perm1,perm2) {
    if(perm1.display===perm2.display) {return 0;}

    const active1 = this.state[perm1.id]
    const active2 = this.state[perm2.id]
    if(active1!==active2) {
      return active1 ? -1 : 1
    }
    return (perm1.display < perm2.display) ? -1 : 1
  }

  handlePermissionClick(id) {
    let obj = {}
    obj[id] = !this.state[id]
    this.setState(obj)
  }

  render() {
    const perms = this.props.allPermissions.sort(this.comparePermissions)
    return <div className="EditRoleForm"><br/>
      <span className="text-center w-100"><h3>{this.props.role.name}</h3></span>
      <h6>Role Permissions</h6>
      <ul className="list-group">
        {perms.filter((perm)=>this.state[perm.id]).map((permission, i)=>(
          <li key={i} onClick={()=>this.handlePermissionClick(permission.id)} className={`list-group-item permission ${this.state[permission.id] ? "" : "inactive"}`}>
            <div className="">{permission.display}</div>
          </li>
        ))}
      </ul>
      <h6>Remaining Permissions</h6>
      <ul className="list-group">
        {perms.filter((perm)=>!this.state[perm.id]).map((permission, i)=>(
          <li key={i} onClick={()=>this.handlePermissionClick(permission.id)} className={`list-group-item permission ${this.state[permission.id] ? "" : "inactive"}`}>
            <div className="">{permission.display}</div>
          </li>
        ))}
      </ul>
      <div onClick={this.onSave} className="btn btn-vega-light w-100">Save</div>
    </div>
  }
}

export default withRouter(EditRoleForm)
