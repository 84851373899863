import React from 'react'
import Parse from 'parse'
import Header from '../../components/desktop/Header'

export default function HeaderContainer() {
  const currUser = Parse.User.current()
  const profilePic = currUser.get("profilePic")
  return (<Header profilePicSrc={profilePic ? profilePic.url() : null}
    firstName={currUser.get("firstName")}
    lastName={currUser.get("lastName")}
    username={currUser.get("username")} />)
}
