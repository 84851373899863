import React from 'react'
import NotificationCell from './NotificationCell'

export default function NotificationList({notifications}) {
  return (<div className="NotificationList">
    {notifications.length===0
      ? <p className="list-message">You don't have any notifications yet.</p>
      : (<div>
          {notifications.map((notification, i)=><NotificationCell key={i} {...notification}/>)}
      </div>)
    }
  </div>)
}
